<template>
	<div style="background-color: #F2F3F5;background-image: url('/static/images/home2bg.png')">
		<div style="min-height: 1px"></div>

		<div class="slider_2" style="background-image: url('/static/images/home2bg.png')">
			<div class="slider_2_shapes">
				<!--				<img class="h2_shape4" src="/static/picture/home_2_shape4.png" alt=""/>-->
				<img class="h2_sh_1 d-none d-sm-block" src="/static/picture/bordershape.png" alt="">
				<img class="h2_sh_2 d-none d-sm-block" src="/static/picture/home2shape2.png" alt="">
				<img class="h2_sh_3 d-none d-sm-block" src="/static/picture/h2shap3.png" alt="">
				<img class="h2_sh_4 d-none d-sm-block" src="/static/picture/h2shape2.png" alt="">
				<img class="h2_sh_5 d-none d-sm-block" src="/static/picture/home_2_shape.png" alt="">
				<img class="h2_sh_6 d-none d-sm-block" src="/static/picture/home_2_shape7.png" alt="">
				<img class="h2_sh_7 d-none d-sm-block" src="/static/picture/border2shape.png" alt="">
			</div>
			<div class="container">
				<div class="row">
					<div style="max-width: 1200px;margin: 100px auto;" v-if="!showStatus">
						<div style="background-color: white;border-radius:5px">
							<el-row style="padding:20px 20px 0 20px">
								<el-col :span="18">
									<div style="font-size: 24px;color: #333333;height: 60px">
										待支付金额：<span style="color: #FF453A;font-size: 40px;font-weight: Bold;font-family: 思源黑体 CN Bold Bold">¥{{order.amt}}元</span>
									</div>
								</el-col>
								<el-col :span="6">
									<div style="color: #333333;font-size: 30px;font-weight: bolder;height: 60px;line-height: 60px;text-align: right">
										<img src="/static/images/webPay/expireTime.png" alt="" style="vertical-align:middle;width: 34px">
										<span style="margin-left: 5px;font-family: 苹方-简 中黑体">{{count}}</span>
									</div>
								</el-col>
							</el-row>
							<el-divider/>
							<el-row style="padding:0 20px 10px 20px;">
								<el-col :span="12">
									<div style="text-align: left;font-size: 16px">
										<span style="color: #999999">商户名称：</span>
										<span>深圳市微行网络科技有限公司</span>
									</div>
								</el-col>
								<el-col :span="12">
									<div style="text-align: left;font-size: 16px">
										<span style="color: #999999">订单编号：</span>
										<span>{{order.orderId}}</span>
									</div>
								</el-col>
							</el-row>
							<el-row style="padding:0 20px 20px 20px">
								<el-col :span="12">
									<div style="text-align: left;font-size: 16px">
										<span style="color: #999999">商品名称：</span>
										<span>{{order.goodsTitle}}</span>
									</div>
								</el-col>
								<el-col :span="12">
									<div style="text-align: left;font-size: 16px">
										<span style="color: #999999">订单日期：</span>
										<span>{{order.createTime}}</span>
									</div>
								</el-col>
							</el-row>
						</div>

						<div style="background-color: white;margin-top: 20px;padding: 20px;border-radius:5px">
							<div style="font-size: 20px;font-weight: bold">
								企业网银
							</div>

							<div style="margin-top: 10px;margin-bottom: 30px">
								<div style="width: 180px;height: 3px;background: #FFC157;border-radius: 5px;display: inline-block"></div>
								<div style="width: 940px;height: 2px;background: #D8D8D8;border-radius: 5px;display: inline-block"></div>
							</div>

							<template v-for="(item,key) in banKList">
								<el-row style="padding:0 20px 20px 20px;">
									<el-col :span="6" v-for="conetnt in banKList[key]" :key="conetnt.id">
										<div style="text-align: center;height: 65px;box-sizing: border-box;">
											<el-image :src="conetnt.img" class="bank-logo-image" @click="selectBank(conetnt)" :class="acctIssrId===conetnt.id?'select-bank':''" @dragstart.prevent/>
										</div>
									</el-col>
								</el-row>
							</template>

							<div id="to-pay-btn" @click="toNext">
								前往网上银行付款
							</div>
						</div>

					</div>
				</div>


				<!--订单状态-->
				<div v-if="showStatus" class="master-div">
					<div style="text-align: center;height: 600px;display:flex;align-items:center;">
						<el-row class="content_div">
							<template v-if="showCancel">
								<el-col :span="8">
									<div style="text-align: right">
										<el-image src="/static/images/webPay/cancelIcon.png" style="width: 120px;"/>
									</div>
								</el-col>
								<el-col :span="16">
									<div style="text-align: left;font-size: 40px;padding-left: 20px">
									<span v-if="order.isExpire !== 1">
											订单已取消
									</span>
										<span v-else-if="order.isExpire === 1">
											订单已过期
									</span>
									</div>
									<div style="text-align: left;font-size: 20px;padding-left: 20px">
										您的订单已取消，请返回微行小程序或APP再次预定
									</div>
								</el-col>
							</template>
							<template v-else-if="showHavePay">
								<el-col :span="8">
									<div style="text-align: right">
										<el-image src="/static/images/webPay/havePayIcon.png" style="width: 120px;"/>
									</div>
								</el-col>
								<el-col :span="16">
									<div style="text-align: left;font-size: 40px;padding-left: 20px">
									<span>
										订单已支付
									</span>
									</div>
									<div style="text-align: left;font-size: 20px;padding-left: 20px">
										您的订单已支付，请返回微行小程序或APP查看
									</div>
								</el-col>
							</template>

						</el-row>
					</div>
				</div>
			</div>
		</div>


	</div>
</template>

<script>

	import {GetApi} from '@/api'
	import {PostApi} from "@/api";

	export default {
		name: "index",
		data() {
			return {
				code: '',
				type: '',
				acctIssrId: '',

				order: {},

				showStatus: false,
				showCancel: false,
				showHavePay: false,
				count: '', //倒计时
				seconds: 0, // 倒计时秒数

				banKList: {
					1: [
						{id: '01020000', img: '/static/images/webPay/bankLogo/ZGGS.png', name: '工商银行'}, // 工商银行
						{id: '01030000', img: '/static/images/webPay/bankLogo/ZGNY.png', name: '农业银行'}, // 农业银行
						{id: '01040000', img: '/static/images/webPay/bankLogo/ZGYH.png', name: '中国银行'}, // 中国银行
						{id: '01050000', img: '/static/images/webPay/bankLogo/ZGJS.png', name: '建设银行'}, // 建设银行
					],
					2: [
						{id: '03010000', img: '/static/images/webPay/bankLogo/JTYH.png', name: '交通银行'}, // 交通银行
						{id: '03020000', img: '/static/images/webPay/bankLogo/ZXYH.png', name: '中信银行'}, // 中信银行
						{id: '03030000', img: '/static/images/webPay/bankLogo/ZGGD.png', name: '光大银行'}, // 光大银行
						{id: '03040000', img: '/static/images/webPay/bankLogo/HXYH.png', name: '华夏银行'}, // 华夏银行
					],
					3: [
						{id: '03050000', img: '/static/images/webPay/bankLogo/MSYH.png', name: '民生银行'}, // 民生银行
						{id: '03060000', img: '/static/images/webPay/bankLogo/GFYH.png', name: '广发银行'}, // 广发银行
						{id: '03080000', img: '/static/images/webPay/bankLogo/ZSYH.png', name: '招商银行'}, // 招商银行
						{id: '03090000', img: '/static/images/webPay/bankLogo/XYYH.png', name: '兴业银行'}, // 兴业银行
					],
					4: [
						{id: '03100000', img: '/static/images/webPay/bankLogo/PFYH.png', name: '浦发银行'}, // 浦发银行
						{id: '03134402', img: '/static/images/webPay/bankLogo/PAYH.png', name: '平安银行'}, // 平安银行
						{id: '04030000', img: '/static/images/webPay/bankLogo/ZGYZ.png', name: '邮储银行'}, // 邮储银行
						{id: '03130011', img: '/static/images/webPay/bankLogo/BJYH.png', name: '北京银行'}, // 北京银行
					],
					5: [
						{id: '03130031', img: '/static/images/webPay/bankLogo/SHYH.png', name: '上海银行'}, // 上海银行
						{id: '03133201', img: '/static/images/webPay/bankLogo/NJYH.png', name: '南京银行'}, // 南京银行
						{id: '03133301', img: '/static/images/webPay/bankLogo/HZYH.png', name: '杭州银行'}, // 杭州银行
						{id: '03160000', img: '/static/images/webPay/bankLogo/zheshangYH.png', name: '浙商银行'}, // 浙商银行
					],
					6: [
						{id: '04020031', img: '/static/images/webPay/bankLogo/SHNS.png', name: '上海农商'}, // 上海农商
						{id: '04478210', img: '/static/images/webPay/bankLogo/LZYH.png', name: '兰州银行'}, // 兰州银行
						{id: '05818200', img: '/static/images/webPay/bankLogo/GSYH.png', name: '甘肃银行'}, // 甘肃银行
						{id: '05847000', img: '/static/images/webPay/bankLogo/GZYH.png', name: '贵州银行'}, // 贵州银行
					],
				}
			}
		},
		async created() {
      let query = this.$route.query;
      if (!query || !query.code) {
        console.log("无法获取code数据");
        return
      }
      if (!query || !query.type) {
        console.log("无法获取type数据");
        return
      }
      this.code = query.code;
      this.type = query.type;
      await this.getOrderDetail(false);
      if (this.order.expireTime) {
        console.log("调用定时器成功")
        this.Time() //调用定时器
      }


    },
		methods: {

			// 天 时 分 秒 格式化函数
			countDown() {
				let d = parseInt(this.seconds / (24 * 60 * 60))
				d = d < 10 ? "0" + d : d
				let h = parseInt(this.seconds / (60 * 60) % 24);
				h = h < 10 ? "0" + h : h
				let m = parseInt(this.seconds / 60 % 60);
				m = m < 10 ? "0" + m : m
				let s = parseInt(this.seconds % 60);
				s = s < 10 ? "0" + s : s
				this.count = d + ':' + h + ':' + m + ':' + s + ''
			},

			//定时器没过1秒参数减1
			Time() {

				let timer = setInterval(() => {
					this.seconds -= 1;
					if (this.seconds < 1) {
						this.getOrderDetail(false);
						clearInterval(timer);
						// window.clearInterval();
					}
					this.countDown()
				}, 1000)

			},

			/**
			 * 获取订单信息
			 */
      async getOrderDetail(isPay) {
				let payload = {
					code: this.code,
          type: this.type
				};
        let aData = await GetApi.getOrderDetail(payload);
        this.order = aData.data;
        if (this.order.orderStatus === -1 || this.order.isExpire === 1) {
          // 已取消
          this.showStatus = true;
          this.showCancel = true;
        } else if (this.order.orderStatus > 1 && this.order.orderStatus < 5 && this.order.payStatus > 0) {
          // 已支付
          this.showStatus = true;
          this.showHavePay = true;
        } else {
          let nowS = new Date().getTime() / 1000; // 当前秒
          let expireTimeS = new Date(this.order.expireTime).getTime() / 1000;
          this.seconds = parseInt(expireTimeS - nowS)

          // 去支付
          if (isPay) {
            this.b2bPay();
          }
        }
			},

			selectBank(info) {
				console.log("选择银行：", info.name);
				this.acctIssrId = info.id
			},
			/**
			 * 下一步
			 */
			toNext() {
				if (!this.acctIssrId) {
					this.$message.error('请先选择银行');
					return
				}
				this.getOrderDetail(true);
			},

			b2bPay() {
				let payload = {
					orderId: this.code,
					acctIssrId: this.acctIssrId,
          type:this.type
				};
				PostApi.payB2b(payload).then((aData) => {
					if (aData.code === 0) {
						let html = aData.data.trans_assembled_url;

						let myWindow = window.open('', 'width:100%,height:100%');
						myWindow.document.write(html);
						myWindow.document.close();
						myWindow.focus();
					}
				}).catch((err) => {
					console.log(err);
				})
			}
		}
	}
</script>

<style scoped>

	.master-div {
		max-width: 1200px;
		background-color: white;
		margin: 100px auto;
		padding-bottom: 200px;
	}

	.content_div {
		height: 268px;
		display: flex;
		align-items: center;
		border: 1px solid rgba(253, 66, 32, 0.2);
		border-radius: 25px;
		width: 890px;
		margin: 0 auto;

	}

	#to-pay-btn {
		height: 40px;
		width: 200px;
		background-color: #FFC157;
		line-height: 40px;
		font-size: 14px;
		font-weight: bolder;
		text-align: center;
		margin: 0 auto;
		cursor: pointer;
		border-radius: 5px;
		margin-top: 60px;
		margin-bottom: 80px
	}

	#to-pay-btn:hover {
		background-color: #DCDFE6;
	}

	.bank-logo-image {
		width: 200px;
		border: 1px solid;
		border-radius: 5px;
	}

	.bank-logo-image:hover {
		box-sizing: border-box;
		border: 3px solid #FFC157;
		transform: scale(1.05);

	}

	.select-bank {
		overflow: hidden;
		/*transform: scale(1,1);*/
		clip: rect(0px 10px 200px 0px);
		border: 3px solid #FFC157;

	}
</style>
